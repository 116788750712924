@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap);
.activeNavItem {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(0%)
    contrast(200%);
}

.ripple {
  background-position: center;
  transition: background 0.5s;
}
.ripple:hover {
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%)
    center/15000%;
}
.ripple:active {
  background-color: #ddd;
  background-size: 100%;
  transition: background 0s;
}

.ReactSwipeButton {
  height: 50px;
  width: 100%;
  position: relative;
  max-width: 300px;
  margin: 0px auto;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
}
.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}
.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0px;
  left: 50px;
  margin-left: -101%;
  background: var(--color-primary);
  border-radius: 25px;
  z-index: 8;
  cursor: pointer;
}
.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
}
.rsbcSliderArrow {
  position: absolute;
  top: 32%;
  right: 14px;
  z-index: 9;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: white;
  top: 5px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 600px) {
  .rsbcSliderCircle {
    right: 2px;
  }
  .rsbcSliderArrow {
    right: 16px;
  }
}

.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 13px;
  font-weight: 700;
  color: white;
}

.checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input:checked + .checkbox-tile {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: var(--color-primary);
}
.checkbox-input:checked:focus + .checkbox-tile {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 6rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-grey-1);
  transition: 0.3s ease;
  cursor: pointer;
  position: relative;
  font-weight: 700;
  background: var(--color-grey-1);
}
.checkbox-tile:before {
  content: '';
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--color-grey-1);
  background: var(--color-grey-1);
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #919191;
}
.checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}

.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 2px solid var(--color-grey-1);
  background: var(--color-grey-1);
  border-radius: 5px;
  width: 100%;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border: 2px solid var(--color-primary);
  transition: 0.3s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  background: var(--color-primary);

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: white;
  margin-left: 12px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  background: #5f52b8;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  background: transparent;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 25px 15px;
}

.react-tags__suggestions li mark {
  background: transparent;
  color: var(--color-primary);
  border-radius: 3px;
  font-weight: 800;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: var(--color-grey-2);
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

html {
  color: #000;
  font-size: 1em;
  line-height: 1.2;
  --color-primary: #4f46e5;
  --color-secondary: #5a5785;
  --color-red: #e54646;
  --color-amber: #df9d4c;
  --color-green: #52ad42;
  --color-blue: #496de1;
  --color-grey-1: #f9f9f9;
  --color-grey-2: #eeeeee;
  --color-grey-3: #d0d0d0;
  --color-error: #fed7d7;
  --color-error-border: #e53e3e;
  --color-warning: #feebc8;
  --color-warning-border: #d29e20;
  --color-info: #bee3f8;
  --color-info-border: #3182ce;
  --color-success: #c6f6d5;
  --color-success-border: #38a169;
  --box-radius: 8px;
  --header-height: 4.5rem;
  --tab-nav-height: 40px;
  --menu-height: 56px;
  --zi-filter-search: 14;
  --zi-filter-input: 13;
  --zi-filter: 12;
  --zi-loading: 11;
  --zi-photoCrop: 11;
  --zi-bottom-nav: 10;
  --zi-header: 10;
  --zi-menu: 9;
  --zi-overlay: 8;
  --zi-tool-tip: 7;
  --shadow-color: 0deg 0% 60%;
  --box-shadow: 0px 0.3px 0.2px hsl(var(--shadow-color) / 0.23),
    0px 0.5px 0.4px -0.5px hsl(var(--shadow-color) / 0.21),
    0px 0.7px 0.6px -1px hsl(var(--shadow-color) / 0.19),
    0px 1.2px 1px -1.5px hsl(var(--shadow-color) / 0.17),
    0px 2.1px 1.7px -2px hsl(var(--shadow-color) / 0.15),
    -0.1px 3.4px 2.8px -2.5px hsl(var(--shadow-color) / 0.13),
    -0.1px 5.4px 4.5px -3px hsl(var(--shadow-color) / 0.11),
    -0.2px 8.2px 6.8px -3.5px hsl(var(--shadow-color) / 0.09),
    -0.2px 11.8px 9.7px -4px hsl(var(--shadow-color) / 0.07),
    -0.3px 16.5px 13.6px -4.5px hsl(var(--shadow-color) / 0.05);
}

body {
  margin: 0;
  font-family: 'Mulish', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  background: var(--color-grey-2);
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

* {
  margin: 0;
}

*:focus {
  outline: none;
}
::-webkit-scrollbar {
  display: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}
button {
  cursor: pointer;
  font-family: 'Mulish', 'Helvetica Neue', sans-serif;
  color: black;
}

::selection {
  color: #fff;
  background: var(--color-primary);
}
::-webkit-scrollbar {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

h1,
p {
  margin: 0;
}
h2 {
  font-weight: 900;
}

.no-scroll {
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: none;
}

