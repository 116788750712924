@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

html {
  color: #000;
  font-size: 1em;
  line-height: 1.2;
  --color-primary: #4f46e5;
  --color-secondary: #5a5785;
  --color-red: #e54646;
  --color-amber: #df9d4c;
  --color-green: #52ad42;
  --color-blue: #496de1;
  --color-grey-1: #f9f9f9;
  --color-grey-2: #eeeeee;
  --color-grey-3: #d0d0d0;
  --color-error: #fed7d7;
  --color-error-border: #e53e3e;
  --color-warning: #feebc8;
  --color-warning-border: #d29e20;
  --color-info: #bee3f8;
  --color-info-border: #3182ce;
  --color-success: #c6f6d5;
  --color-success-border: #38a169;
  --box-radius: 8px;
  --header-height: 4.5rem;
  --tab-nav-height: 40px;
  --menu-height: 56px;
  --zi-filter-search: 14;
  --zi-filter-input: 13;
  --zi-filter: 12;
  --zi-loading: 11;
  --zi-photoCrop: 11;
  --zi-bottom-nav: 10;
  --zi-header: 10;
  --zi-menu: 9;
  --zi-overlay: 8;
  --zi-tool-tip: 7;
  --shadow-color: 0deg 0% 60%;
  --box-shadow: 0px 0.3px 0.2px hsl(var(--shadow-color) / 0.23),
    0px 0.5px 0.4px -0.5px hsl(var(--shadow-color) / 0.21),
    0px 0.7px 0.6px -1px hsl(var(--shadow-color) / 0.19),
    0px 1.2px 1px -1.5px hsl(var(--shadow-color) / 0.17),
    0px 2.1px 1.7px -2px hsl(var(--shadow-color) / 0.15),
    -0.1px 3.4px 2.8px -2.5px hsl(var(--shadow-color) / 0.13),
    -0.1px 5.4px 4.5px -3px hsl(var(--shadow-color) / 0.11),
    -0.2px 8.2px 6.8px -3.5px hsl(var(--shadow-color) / 0.09),
    -0.2px 11.8px 9.7px -4px hsl(var(--shadow-color) / 0.07),
    -0.3px 16.5px 13.6px -4.5px hsl(var(--shadow-color) / 0.05);
}

body {
  margin: 0;
  font-family: 'Mulish', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  background: var(--color-grey-2);
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

* {
  margin: 0;
}

*:focus {
  outline: none;
}
::-webkit-scrollbar {
  display: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}
button {
  cursor: pointer;
  font-family: 'Mulish', 'Helvetica Neue', sans-serif;
  color: black;
}

::-moz-selection {
  background: var(--color-primary);
  text-shadow: none;
  color: #fff;
}

::selection {
  color: #fff;
  background: var(--color-primary);
}
::-webkit-scrollbar {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

h1,
p {
  margin: 0;
}
h2 {
  font-weight: 900;
}

.no-scroll {
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: none;
}
