.activeNavItem {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(0%)
    contrast(200%);
}

.ripple {
  background-position: center;
  transition: background 0.5s;
}
.ripple:hover {
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%)
    center/15000%;
}
.ripple:active {
  background-color: #ddd;
  background-size: 100%;
  transition: background 0s;
}
