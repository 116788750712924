.ReactSwipeButton {
  height: 50px;
  width: 100%;
  position: relative;
  max-width: 300px;
  margin: 0px auto;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
}
.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}
.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0px;
  left: 50px;
  margin-left: -101%;
  background: var(--color-primary);
  border-radius: 25px;
  z-index: 8;
  cursor: pointer;
}
.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
}
.rsbcSliderArrow {
  position: absolute;
  top: 32%;
  right: 14px;
  z-index: 9;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: white;
  top: 5px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 600px) {
  .rsbcSliderCircle {
    right: 2px;
  }
  .rsbcSliderArrow {
    right: 16px;
  }
}

.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 13px;
  font-weight: 700;
  color: white;
}
